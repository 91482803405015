import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  constructor(public detector: DeviceDetectorService) { }
  public isMobile;
  ngOnInit() {
  	this.isMobile = this.detector.isMobile();
  	console.log(this.isMobile);
  }

}
