import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { BusinessComponent } from './components/business/business.component';
import { SupportComponent } from './components/support/support.component'
import { SupportSingleComponent } from './components/support-single/support-single.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { LandingComponent } from './components/landing/landing.component';
import { SupportSearchComponent } from './components/support-search/support-search.component';
import { PolicyComponent } from './components/policy/policy.component';
import { TermsComponent } from './components/terms/terms.component';
import { PromocodesComponent } from './components/promocodes/promocodes.component';
import { RegulatorComponent } from './components/regulator/regulator.component';

const routes: Routes = [
	{ path: '', redirectTo: 'landing', pathMatch: 'full' },
	{ path: '', component: LandingComponent, pathMatch: 'full'},
	{ path: 'business', component: BusinessComponent, children: [
		{ path: '', redirectTo: 'change', pathMatch: 'full'}
	]},
	{ path: 'support', component: SupportComponent, children: [
	 	{ path: '', component: SupportComponent, pathMatch: 'full' }
	]},
	{ path: 'support/:category', component: SupportSingleComponent, pathMatch: 'full'},
	{ path: 'support/search/:query', component: SupportSearchComponent, pathMatch: 'full'},
	{ path: 'policy', component: PolicyComponent, pathMatch: 'full'},
	{ path: 'terms', component: TermsComponent, pathMatch: 'full'},
	{ path: 'regulator', component: RegulatorComponent, pathMatch: 'full'},
	{ path: 'notfound', component: NotfoundComponent, pathMatch: 'full' },
	{ path: 'promocodes', component: PromocodesComponent, pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
