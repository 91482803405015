export const faq = [
	{
      theme: 'Crear una cuenta',
      questions: [
        {
          question: '¿Qué es el periodo de pruebas?',
          answer: 'Necesitamos poner a punto toda la plataforma que hemos desarrollado y el periodo de pruebas comprende la interacción de clientes reales con nuestras aplicaciones en condiciones reales, de esta manera comprobamos que todo funciona a la perfección.',
          open: false
        }
      ]
    },
    {
      theme: 'Realizar un depósito',
      questions: [
        {
          question: '¿Cómo realizo una compra de dólares?',
          answer: 'Para comprar dólares, debes indicar los montos de tu operación y aceptar las condiciones que muestra la plataforma, indicar desde qué banco harás la transferencia de los soles a nuestras cuentas y en qué cuenta deseas recibir los dólares que estás comprando. Luego de transferir los soles, confirma esta operación ingresando el número de operación respectiva, y listo... al poco tiempo recibirás los dólares en la cuenta bancaria que nos indicaste.',
          open: false
        },
        {
          question: '¿Cómo realizo una venta de dólares?',
          answer: 'Para vender dólares debes indicar los montos de tu operación y aceptar las condiciones que muestra la plataforma, indicar desde qué banco harás la transferencia de los dólares a nuestras cuentas y en qué cuenta deseas recibir los soles producto de la venta, Luego de transferir los dólares a nuestras cuentas, confirmar esta operación ingresando el número de operación respectiva y listo… ya vendiste tus dólares, al poco tiempo recibirás los soles en la cuenta bancaria que nos indicaste.',
          open: false
        },
        // {
        //   question: '¿Cómo creo un Depósito?',
        //   answer: 'Escoges en el menú de operaciones "Crear Depósito" luego nos indicas la cuenta en Decamoney donde depositaremos y el monto correspondiente. La plataforma te dará las instrucciones para realizar la transferencia bancaria, una vez realizada nos confirmas indicándonos el número de la operación y listo... haz hecho un Depósito en tu cuenta Decamoney.',
        //   open: false
        // },
        // {
        //   question: '¿Cómo ingreso una orden de cambio?',
        //   answer: 'Escoges en el menú de operaciones “Transferir / Cambio” luego escoges la cuenta donde tienes los fondos que deseas cambiar, la cuenta origen; y seguidamente la cuenta de destino donde abonaremos el monto cambiado. Ahora debes ingresar el monto a cambiar o la cantidad que deseas recibir y ordenas hacer el cambio. La plataforma te mostrará los detalles de esta operación.',
        //   open: false
        // },
        // {
        //   question: '¿Cómo creo un Retiro?',
        //   answer: 'Escoges en el menú de operaciones “ Crear Retiro” luego indicas la cuenta en Decamoney donde está el dinero que quieres retirar, enseguida la cuenta bancaria donde deseas recibir la transferencia de dichos fondos y el monto a retirar y creas el retiro. La plataforma realizará una transferencia por dicho monto a la cuenta indicada, solo puedes retirar fondos que estén disponibles.',
        //   open: false
        // },
        // {
        //   question: '¿Por qué la orden es de "Transferencia/Cambio"?',
        //   answer: 'Decamoney te permite organizar tus finanzas de acuerdo a tus necesidades para esto puedes crear en Decamoney todas las cuentas que necesites y transferir fondos entre ellas. Si transfieres entre cuentas denominadas en diferente moneda estás realizando una operación de cambio.',
        //   open: false
        // }
        {
          question: '¿Como hago el seguimiento a una operación de cambio?',
          answer: 'Luego de ingresada una operación de cambio, en la pantalla principal me dirijo a la sección "ORDENES" al lado de este título se muestra el número de operaciones pendientes antes de completar tu cambio. Estas suelen ser tres: Primero la transferencia de los fondos a cambiar, segundo la operación de cambio de moneda y tercero la transferencia de fondos a la cuenta bancaria indicada. Según se va atendiendo tu orden estas cambian de estado: de HOLD a PENDING y luego a COMPLETED. De esta manera siempre sabrás en que estado se encuentra tu orden de cambio.',
          open: false
        }
      ]
    },
    {
      theme: 'Mis cuentas',
      questions: [
        {
          question: '¿Qué ventajas obtengo al usar Decamoney?',
          answer: 'Cambiar dinero usando la plataforma Decamoney te permite ahorrar tiempo y sobre todo te da seguridad, pues evita que te desplaces transportando efectivo, evitando que te puedan robar, engañar con dinero falso o montos inexactos. Y nuestros precios te permiten recibir más por tu dinero.',
          open: false
        }
      ]
    },
    {
      theme: 'Transferencias - Cambios',
      questions: [
        {
          question: '¿Cómo se calcula el tipo de cambio que ofrecemos?',
          answer: 'Al igual que una mesa de cambio de cualquier banco, nosotros seguimos la cotización más representativa que es el precio del dólar interbancario, en base a este valor determinamos a qué precio comprar y vender, nuestros precios son siempre competitivos y reflejan las condiciones del mercado.',
          open: false
        },
        {
          question: '¿Cada cuanto tiempo cambian los precios en la plataforma?',
          answer: 'El mercado cambiario es muy volátil, los precios pueden variar bruscamente. En nuestra plataforma observamos las variaciones de este y de acuerdo a esas variaciones fijamos nuestros precios, en un día tranquilo los precios pueden permanecer sin variar por un buen rato pero en una jornada agitada los cambios de precio pueden darse de manera continua.',
          open: false
        },
        {
          question: '¿Que es una transferencia interbancaria?',
          answer: 'La plataforma opera en estos momentos con cuentas en el BCP, cualquier transferencia hacia o desde un banco diferente será realizada mediante una transferencia interbancaria. Nuestro sistema bancario permite realizar transferencias interbancarias inmediatas o diferidas. Para estas operaciones usamos el Código de Cuenta Interbancario CCI.',
          open: false
        },
        {
          question: '¿Que es una transferencia interbancaria inmediata?',
          answer: 'Transferencias Interbancarias Inmediatas son transferencias de dinero que se realizan entre dos entidades financieras y que son abonadas al instante.   A la fecha, las entidades que participan son: BanBif, Banco de Comercio, Banco de la Nación, Banco Pichincha, Banco GNB, BBVA, BCP, Caja Arequipa, Caja Piura, Caja Sullana, Caja Trujillo, Citibank, Crediscotia, Interbank, Mibanco y Scotiabank. Estas transferencias se llevan a cabo dentro de un horario, de lunes a viernes de 9:00 am a 4:00 pm, el monto máximo a transferir es de USD 10,000 o su equivalente en PEN (soles).',
          open: false
        },
        {
          question: '¿Que es una transferencia interbancaria diferida?',
          answer: `Este es el servicio de transferencia de uso más general entre los bancos y cajas en todo el Perú, el sistema realiza rondas de transferencia en varios momentos del día, por tanto las transferencias pueden demorar hasta algunas horas. 
          Si haces una transferencia hasta las 11:30am, recibirás el dinero en la otra cuenta a las 2:00pm
          Si haces una transferencia hasta la 1:30pm, recibirás el dinero en la otra cuenta a las 5:15pm
          Si haces una transferencia hasta las 7:00pm,  recibirás el dinero al día siguiente a las 10:00am`,
          open: false
        },
        {
          question: '¿Cuanto cuesta una transferencia interbancaria?',
          answer: `Las transferencias interbancarias diferidas en una misma plaza o ciudad tienen un costo que será asumido por el cliente de la cuenta origen. El costo depende de cada entidad financiera y puede variar entre S/2 a S/5.
          Las transferencias interbancarias inmediatas en una misma plaza o ciudad tienen un costo que será asumido por el cliente de la cuenta origen. El costo depende de cada entidad financiera y puede variar entre S/4 y S/7.
          Los costos se incrementan cuando la transferencia es entre cuentas de diferente plaza o ciudad.`,
          open: false
        },
        {
          question: '¿Que es el código CCI? ¿Como lo obtengo?',
          answer: 'El Código de Cuenta Interbancario CCI permite identificar una cuenta del sistema bancario peruano como único de esta manera se facilita la transferencia de dinero entre cuentas de cualquier banco o caja. Todos los CCI tienen 20 dígitos, tu banco te indica el CCI correspondiente a la cuenta que tengas en esa institución.',
          open: false
        },
      ]
    },
    {
    	theme: 'Retirar fondos',
    	questions: [
    		{
	          question: '¿Pregunta de ejemplo - Retirar fondos?',
	          answer: 'Al igual que una mesa de cambio de cualquier banco, nosotros seguimos la cotización más representativa que es el precio del dólar interbancario, en base a este valor determinamos a qué precio comprar y vender, nuestros precios son siempre competitivos y reflejan las condiciones del mercado.',
	          open: false
	        }
    	]
    },
    {
    	theme: 'Otros',
    	questions: [
    		{
	          question: '¿Pregunta de ejemplo - Otros?',
	          answer: 'Al igual que una mesa de cambio de cualquier banco, nosotros seguimos la cotización más representativa que es el precio del dólar interbancario, en base a este valor determinamos a qué precio comprar y vender, nuestros precios son siempre competitivos y reflejan las condiciones del mercado.',
	          open: false
	        }
    	]
    }
];