import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-promocodes',
  templateUrl: './promocodes.component.html',
  styleUrls: ['./promocodes.component.css']
})
export class PromocodesComponent implements OnInit {
	public name = 'hello';
	public coupons = [
      {
      text: ' vence el 31 de diciembre',
			date: '15 de diciembre del 2021 hasta el 31 de diciembre del 2021',
			disscount: '0.35',
			name: 'DECAFIESTAS',
			terms: false
		}, 
	];

	public expired = [
 {
      text: ' venció el 31 de julio',
			date: '15 de julio del 2021 hasta el 31 de julio del 2021',
			disscount: '0.40',
			name: 'PERU200',
			terms: false
		},
    {
      text: ' venció el 31 de mayo',
			date: '03 de mayo del 2021 hasta el 31 de mayo del 2021',
			disscount: '0.42',
			name: 'ANIVERMONEY',
			terms: false
		},  
    {
      text: ' venció el 31 de marzo',
			date: '17 de marzo del 2021 hasta el 31 de marzo del 2021',
			disscount: '0.40',
			name: 'CLASES2021',
			terms: false
		},    
    {
      text: ' venció el 28 de febrero',
			date: '15 de febrero del 2021 hasta el 28 de febrero del 2021',
			disscount: '0.40',
			name: 'PROTEGIDOS',
			terms: false
		},    
    {
			text: ' venció el 31 de enero',
			date: '15 de enero del 2021 hasta el 31 de enero del 2021',
			disscount: '0.42',
			name: 'DECAVERANO',
			terms: false
		},
    {
			text: ' venció el 31 de diciembre',
			date: '09 de diciembre del 2020 hasta el 31 de diciembre del 2020',
			disscount: '0.42',
			name: 'NAVIDECAMONEY',
			terms: false
		},	
    {
			text: ' venció el 30 de noviembre',
			date: '19 de noviembre del 2020 hasta el 30 de noviembre del 2020',
			disscount: '0.45',
			name: 'SEGURIDAD',
			terms: false
		},		
    {
			text: ' venció el 31 de octubre',
			date: '16 de octubre del 2020 hasta el 31 de octubre del 2020',
			disscount: '0.45',
			name: 'NOHAYTRUCO',
			terms: false
		},
    {
			text: ' venció el 30 de septiembre',
			date: '16 de septiembre del 2020 hasta el 30 de septiembre del 2020',
			disscount: '0.45',
			name: 'PRIMAVERA',
			terms: false
		},
		{
			text: ' venció el 31 de agosto',
			date: '15 de agosto del 2020 hasta el 31 de agosto del 2020',
			disscount: '0.45',
			name: 'CAMBIOS',
			terms: false
		},
		{
			text: ' venció el 31 de julio',
			date: '15 de julio del 2020 hasta el 31 de julio del 2020',
			disscount: '0.5',
			name: 'CONTIGOPERU',
			terms: false
		},
		{
			text: ' venció el 30 de junio',
			date: '8 de junio del 2020 hasta el 30 de junio del 2020',
			disscount: '0.55',
			name: 'CAMBIOJUNIO',
			terms: false
		},
		{
			text: ` venció el 30 de junio`,
			date: '15 de junio del 2020 hasta el 30 de junio del 2020',
			disscount: '0.55',
			name: 'PROTEGIDOS',
			terms: false
		},
		{
			text: ' venció el 31 de mayo',
			date: '21 de mayo del 2020 hasta el 30 de mayo del 2020',
			disscount: '0.6',
			name: 'LANZAMIENTO',
			terms: false
		}
	];

  constructor() { }

  ngOnInit() {
  }

  toBold(word){
  	return word.bold();
  }

}
