import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layouts/header/header.component';
import { NavBarComponent } from './layouts/nav-bar/nav-bar.component';
import { HomeComponent } from './components/home/home.component';
import { BusinessComponent } from './components/business/business.component';
import { SupportComponent } from './components/support/support.component';
import { NotfoundComponent } from './components/notfound/notfound.component';

import { DeviceDetectorModule } from 'ngx-device-detector';
import { LandingComponent } from './components/landing/landing.component';
import { FooterComponent } from './layouts/footer/footer.component';

import { ListenService } from './providers/listen.service';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { TwoDigitDecimaNumberDirective } from './two-digit-input.directive';
import { TooltipModule } from 'ng2-tooltip-directive';
import { SupportSingleComponent } from './components/support-single/support-single.component';
import { SupportSearchComponent } from './components/support-search/support-search.component';
import { PolicyComponent } from './components/policy/policy.component';
import { TermsComponent } from './components/terms/terms.component';
import { RegulatorComponent } from './components/regulator/regulator.component';
import { PromocodesComponent } from './components/promocodes/promocodes.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NavBarComponent,
    HomeComponent,
    BusinessComponent,
    SupportComponent,
    NotfoundComponent,
    LandingComponent,
    FooterComponent,
    TwoDigitDecimaNumberDirective,
    SupportSingleComponent,
    SupportSearchComponent,
    PolicyComponent,
    TermsComponent,
    RegulatorComponent,
    PromocodesComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpModule,
    TooltipModule,
    SlickCarouselModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    DeviceDetectorModule.forRoot()
  ],
  providers: [ListenService],
  bootstrap: [AppComponent]
})
export class AppModule { }
