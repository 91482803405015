import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import * as preguntas from './faq';

@Component({
  selector: 'app-support-search',
  templateUrl: './support-search.component.html',
  styleUrls: ['./support-search.component.css']
})
export class SupportSearchComponent implements OnInit {
	public categories:Array<any> = ['Crear una cuenta','Realizar un depósito','Mis cuentas','Transferencias - Cambios','Retirar fondos', 'Otros'];
  	public faq = [];
    public search = '';
  constructor(public router:Router,
  	public activatedR: ActivatedRoute
  	) { }

  ngOnInit() {
  	this.activatedR.params
  		.subscribe(param => {
        this.faq = [];
        this.search = param.query;
  			for (let i = 0; i < preguntas.faq.length; i++) {
  				for (let o = 0; o < preguntas.faq[i].questions.length; o++) {
            if (preguntas.faq[i].questions[o].question.includes(this.search.toLowerCase())) {
              this.faq.push(preguntas.faq[i].questions[o]);
            }
  				}
  			}
  		});
  }

  onkeyW(evt) {
    if (evt.key == 'Enter') {
      this.router.navigate(['/support/search/' + this.search]);
    }
  }

  open(qs){
    qs.open = !qs.open;
  }

  change(item){
    this.router.navigate(['/support/' + item])
  }

}
