import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

let apiUrl = 'https://banner-notification-service.herokuapp.com';

@Injectable({
  providedIn: 'root'
})
export class BannerNotificationsService {

  headers:HttpHeaders;
  constructor(private http: HttpClient) {}

  getNotifications(){
    return new Promise((resolve, reject) => {
      this.http.get(`${apiUrl}/api/notifications`, {headers: this.headers})
      .subscribe(res => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    })
  }

}
