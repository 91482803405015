import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelpersService {
 
  constructor() { }

  roundExchange(amount, decimal) {
    return (+(Math.round(+(amount + 'e' + decimal)) + 'e' + -decimal)).toFixed(decimal);
  }

  calculateAmountsExchange(amount, origin: boolean, final_rate, origin_pen: boolean) {
    var buy = (amount * final_rate) + 0.0001;
    var sell = (amount / final_rate) + 0.0001;
    if (!amount) return ''
    if (origin) return origin_pen ? this.roundExchange(sell, 2) : this.roundExchange(buy, 2)
    if (!origin) return origin_pen ? this.roundExchange(buy, 2) : this.roundExchange(sell, 2)
  }

}
