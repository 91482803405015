import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import * as preguntas from './faq';

@Component({
  selector: 'app-support-single',
  templateUrl: './support-single.component.html',
  styleUrls: ['./support-single.component.css']
})
export class SupportSingleComponent implements OnInit {
	public title:string = '';
  public categories:Array<any> = ['Crear una cuenta','Realizar un depósito','Mis cuentas','Transferencias - Cambios','Retirar fondos', 'Otros'];
  public faq;
  public search = '';
  constructor(public router:Router,
  	public activatedR: ActivatedRoute
  	) { }

  ngOnInit() {
  	this.activatedR.params
  		.subscribe(param => {
  			this.title = param.category;
        let indice = this.categories.findIndex(cat => cat == this.title);
        this.faq = preguntas.faq[indice].questions;
  		});
  }

  change(item){
    this.router.navigate(['/support/' + item])
  }

  open(qs){
    qs.open = !qs.open;
  }

  onkeyW(evt) {
    if (evt.key == 'Enter') {
      this.router.navigate(['/support/search/' + this.search]);
    }
  }

}