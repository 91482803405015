import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {
  public search = '';
  constructor(public router: Router) { }

  ngOnInit() {
  }

  ngAfterViewChecked(){
  	window.scrollTo(0, 0);
  }

  open(category){
  	this.router.navigate(['/support/' + category]);
  }

  onkeyW(evt) {
    if (evt.key == 'Enter') {
      this.router.navigate(['/support/search/' + this.search]);
    }
  }

}