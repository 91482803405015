import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { DecamoneyService } from  '../../providers/decamoney.service';
import { ListenService } from '../../providers/listen.service';
import { EmailService } from '../../providers/email.service';
import { HelpersService } from 'src/app/providers/helpers.service';
import { BannerNotificationsService } from 'src/app/providers/banner-notifications.service';

interface NotificationsInterface {
  id: string, 
  message: string,
  can_be_dismiss: boolean,
  created_at: string,
  updated_at: string,
}

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
  animations: [
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(180deg)', filter: 'blur(0)' })),
      transition('rotated => default', animate('700ms ease-out')),
      transition('default => rotated', animate('700ms ease-in'))
    ])
  ]
})
export class LandingComponent implements OnInit {
  public correo: FormGroup;
  public sended:boolean = false;
  state:string = 'default';
  public showGoTop:boolean = false;
  public steps:Array<any> = [true, false,false,false];
  public defaultSendCurrencyUSD:boolean = true;
  public exchange_rate;
  public notifications: NotificationsInterface[] = []
  public checkbox = {
    checkone: false,
    checktwo: false
  }
  @ViewChild('qualities') qualities: ElementRef;
  @ViewChild('services') services: ElementRef;
  @ViewChild('how') how: ElementRef;
  @ViewChild('who') who: ElementRef;
  @ViewChild('contact') contact: ElementRef;
  toolOpt = {
    'placement': 'top',
    'show-delay': 200,
    'tooltip-class': 'tooltip'
  }
  public texts = ['Lorem ipsum Seguridad sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat', 'Lorem ipsum Tiempo sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat', 'Lorem ipsum Comodidad sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat', 'Lorem ipsum Precio sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat'];
  currentText = this.texts[0];
  slides = [
    {
      img: "./assets/imgs/prices.png",
      text: 'Seguridad'
    },
    {
      img: "./assets/imgs/increase.png",
      text: 'Tiempo'
    },
    {
      img: "./assets/imgs/chart.png",
      text: 'Comodidad'
    },
    {
      img: "./assets/imgs/dollar.png",
      text: 'Mejor precio'
    }
  ];
  slideConfig = {"slidesToShow": 1, "slidesToScroll": 1, arrows: false, dots: true};
  public order = {
    origin: 1500,
    destination: 0
  };
  public rate = {
    buy : 0,
    sell : 0,
  }
  constructor(
    private _fb: FormBuilder,
    public emailServ: EmailService,
    public acrouter: ActivatedRoute,
    public listensv: ListenService,
    private router: Router,
    public decamoney: DecamoneyService,
    public helper: HelpersService,
    public banner_notification: BannerNotificationsService) {
    this.listensv.listen().subscribe((val) => {
      if (val == 'logo') {
        this.goTop();
      } else {
        this.detectSection(val);
      }
    });
  }

  @HostListener('window:scroll') hello(){
    let appear = window.innerHeight + window.scrollY > document.body.scrollHeight - 300;
    this.showGoTop = appear;
  }

  goTop(){
    window.scroll({top: 0, behavior: 'smooth'})
  }

  operation(){
    window.open("http://localhost:3333/login", "_blank");
  }

  ngOnInit() {
    this.acrouter.queryParams.subscribe(params  => {
      // this.detectSection(params.section);
    });
    this.correo = this._fb.group({
      fullname: ["", Validators.required],
      email: ["", Validators.required],
      phone: ["", Validators.required],
      message: ["", Validators.required],
      subject: ["", Validators.required],
      checkone: [null, Validators.required]
    });
    this.decamoney.getRates()
      .then((res:any) => {
        this.rate.buy = res['exchange_rate'].buy
        this.rate.sell = res['exchange_rate'].sell
        this.exchange_rate = this.defaultSendCurrencyUSD ? this.rate.buy : this.rate.sell
        this.calculate(null,1500, true);
      });
    this.banner_notification.getNotifications()
      .then((res: any) => {
        this.notifications = res
        console.log(this.notifications)
      })
  }

  scrollToSection(section){
    let dom = section.nativeElement as HTMLElement;
    window.scroll({top: dom.offsetTop - 70, behavior: 'smooth'});
  }

  changeStep(index){
    this.steps = [false,false,false,false];
    this.steps[index] = true;
  }

  detectSection(number){
    let section;
    if (number == 0) {
      section = this.qualities;
    } else if (number == 1) {
      section = this.services;
    } else if (number == 2) {
      section = this.how;
    } else if ( number == 3){
      section = this.who;
    } else {
      section = this.contact;
    }
    this.scrollToSection(section);
  }


  select(number){
    this.currentText = this.texts[number];
  }

  calculate(evt, amount, origin?){
    origin ? 
        this.order.destination = Number(this.helper.calculateAmountsExchange(amount, origin, this.exchange_rate, !this.defaultSendCurrencyUSD))
        :
        this.order.origin = Number(this.helper.calculateAmountsExchange(amount, origin, this.exchange_rate, !this.defaultSendCurrencyUSD))
  }

  swapCurrencies(){
    this.state = (this.state === 'default' ? 'rotated': 'default');
    this.defaultSendCurrencyUSD = !this.defaultSendCurrencyUSD;
    this.exchange_rate = this.defaultSendCurrencyUSD ? this.rate.buy : this.rate.sell
    this.defaultSendCurrencyUSD ? this.calculate(null, this.order.origin, true) : this.calculate(null, this.order.origin, true)
  }

  open(url){
    window.open(url, '_blank');
  }

  onSubmit(value, valid){
    console.log(value);
    if (valid) {
      this.emailServ.sendEmail("https://deca-landing.herokuapp.com/sendmail", value).subscribe((res)=> {
        this.sended = true;
      });
    } else {

    }
  }

  removeNotification(id: string) {
    this.notifications = this.notifications.filter((notification) => notification.id != id)
  }
}