import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

let apiUrl = 'https://api.decamoney.com';

@Injectable({
  providedIn: 'root'
})
export class DecamoneyService {
  headers:HttpHeaders;
  constructor(private http: HttpClient) {}

  setHeaders(){
      this.headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-User-Email': JSON.parse(localStorage.getItem('user')).user.email,
        'X-User-Token': JSON.parse(localStorage.getItem('user')).user.authentication_token
      });
    }

    getExchangeRate(origin, destination){
      return new Promise((resolve, reject) => {
        this.http.get(`${apiUrl}/v1/rates?origin=${origin}&destination=${destination}`, {headers: this.headers})
        .subscribe(res => {
          resolve(res);
        }, (err) => {
          reject(err);
        });
      })
    }

    getRates() {
      return new Promise( (resolve, reject) => {
        this.http.get(apiUrl + '/v1/rates')
          .subscribe( res => {
            resolve(res);
          }, (err) => {
            reject(err);
          });
      });
    }
}