import { Component, OnInit } from '@angular/core';
import { ListenService } from '../../providers/listen.service';
import { RouterModule, Routes, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public showCross:boolean = false;
  public showBanner:boolean = false;
  public type:String = '';

  constructor(public listen:ListenService, public router: Router) { }

  ngOnInit() {
    this.type = this.getMobileOperatingSystem();
    if (this.type == 'ios' || this.type == 'android') {
      this.showBanner = true;
    } else {
      this.showBanner = false;
    }
  }

  scrollTo(section){
	  this.listen.filter(section);
    if (this.router.url == '/') {
      this.listen.filter(section);
    } else {
      this.router.navigate(['/'], { queryParams: {section: section }});
    }
  }

  example(){
    this.showCross = !this.showCross;
  }

  navigateTo(login){
  	let url = login?'https://cryptic-peak-40574.herokuapp.com/login':'https://cryptic-peak-40574.herokuapp.com/register/one';
  	window.open(url, "_blank");
  }

  closeBanner(){
    this.showBanner = false;
  }

  logo(){
    if (this.router.url == '/') {
      this.listen.filter('logo');
    } else {
      this.router.navigate(['/']);
    }
  }

  getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor;
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return "ios";
    }

    return "unknown";
  }

  download(){
    let url = this.type=='ios'?'https://apps.apple.com/pe/app/decamoney/id1491240859':'https://play.google.com/store/apps/details?id=com.decamoney.decamoney';
    window.open(url, "_blank");
  }

}